import { gsap } from "gsap/all"
import React, { useEffect, useRef } from "react"

const SectionLine = ({
  width,
  background,
  opacity,
  animated,
  animationOptions,
}) => {
  const ref = useRef()

  useEffect(() => {
    if (!animated) return

    const animation = gsap.from(ref.current, {
      scrollTrigger: {
        trigger: animationOptions.trigger,
        start: animationOptions.top || "top center",
      },
      scaleX: 0,
      duration: 1,
      delay: 0.8,
      ease: "power3.easeInOut",
    })

    return () => {
      animation.kill()
    }
  }, [animated])

  return (
    <div
      ref={ref}
      className={`w-${width ? width : "full"} h-px bg-${
        background ? background : "negative"
      } bg-opacity-${opacity ? opacity : "12"}`}
    ></div>
  )
}

export default SectionLine

import React from "react"
import { usePageContext } from "../providers/pageContext"
import { Link as GatsbyLink } from "gatsby"
import linkResolver from "../utils/linkResolver"
import pages from "../config/pages"

const Link = ({ to, slug, ref, newTab, ...rest }) => {
  const pageContext = usePageContext()

  return newTab ? (
    <a
      {...rest}
      href={`${linkResolver(pages[to], pageContext.lang)}${slug ? slug : ""}`}
      target="_blank"
      rel="noreferrer"
    />
  ) : (
    <GatsbyLink
      {...rest}
      to={`${linkResolver(pages[to], pageContext.lang)}${slug ? slug : ""}`}
    />
  )
}

export default Link

import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import gsap from "gsap"

import Circle from "./Circle"

import { setupPIXI, Orb, ColorPalette } from "../utils/pixi"

const FluidBackground = ({ maxOrbs, baseColor, delay }) => {
  const canvasRef = useRef()
  const backgroundRef = useRef(null)
  const pixiApp = useRef()
  const timeout = useRef()

  const breakpoints = useBreakpoint()

  useEffect(() => {
    const init = async () => {
      const { PIXI, KawaseBlurFilter } = await setupPIXI()

      pixiApp.current = new PIXI.Application({
        view: canvasRef.current,
        resizeTo: window,
        backgroundAlpha: 0,
        antialias: true,
        autoDensity: true,
        autoStart: false,
      })

      const colorPalette = new ColorPalette(baseColor)

      const orbs = []

      for (let i = 0; i < maxOrbs; i++) {
        const orb = new Orb(colorPalette.randomColor(), PIXI)
        pixiApp.current.stage.addChild(orb.graphics)

        orbs.push(orb)
      }

      pixiApp.current.stage.filters = [new KawaseBlurFilter(30, 10, true)]

      if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
        pixiApp.current.ticker.add(() => {
          // update and render each orb, each frame. app.ticker attempts to run at 60fps
          orbs.forEach(orb => {
            orb.update()
            orb.render()
          })
        })
      } else {
        // perform one update and render per orb, do not animate
        orbs.forEach(orb => {
          orb.update()
          orb.render()
        })
      }
    }

    if (!breakpoints.lg) return

    init().then(() => {
      timeout.current = setTimeout(() => pixiApp.current.start(), delay)

      gsap.to(backgroundRef.current, {
        scrollTrigger: {
          trigger: "#intro",
          start: "top bottom",
          end: "top top",
          scrub: 1,
          onEnterBack: () => {
            if (backgroundRef.current)
              backgroundRef.current.style.display = "block"

            pixiApp.current.start()
          },
        },
        opacity: 0,
        ease: "power3.easeOut",
        onComplete: () => {
          if (backgroundRef.current)
            backgroundRef.current.style.display = "none"

          pixiApp.current.stop()
        },
      })
    })
    return () => {
      clearTimeout(timeout.current)
    }
  }, [breakpoints.lg])

  return (
    <div
      ref={backgroundRef}
      className="fixed w-full h-full overflow-hidden bg-positive hidden lg:block"
    >
      <div
        className="absolute"
        style={{
          left: "39.1%",
          right: "-2.43%",
          top: "-40.67%",
          bottom: "38.56%",
        }}
      >
        <Circle
          size="lg"
          color="black"
          opacity="60"
          className="mix-blend-overlay"
        />
      </div>
      <div
        className="absolute"
        style={{
          left: "-21.88%",
          right: "58.54%",
          top: "34.67%",
          bottom: "-36.78%",
        }}
      >
        <Circle
          size="lg"
          color="black"
          opacity="60"
          className="mix-blend-overlay"
        />
      </div>
      <div
        className="absolute"
        style={{
          top: "50%",
          left: "50%",
          transform: "translateX(-50%) translateY(-50%)",
        }}
      >
        <Circle size="md" color="white" opacity="100" blur="2xl" />
      </div>
      <canvas
        ref={canvasRef}
        className="orb-canvas absolute max-w-full"
      ></canvas>
    </div>
  )
}

FluidBackground.propTypes = {
  maxOrbs: PropTypes.number,
  baseColor: PropTypes.shape({}),
  delay: PropTypes.number,
}

FluidBackground.defaultProps = {
  maxOrbs: 10,
  baseColor: {
    hue: 240,
    saturation: 100,
    lightness: 58,
  },
  delay: 3000,
}

export default FluidBackground

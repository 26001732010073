import React, { createContext, useReducer, useContext } from "react"
import Modal from "@components/Modal"

const DEFAULT_STATE = {
  visible: false,
  data: null,
}

const toggleOverflow = visible => {
  const [body] = document.getElementsByTagName("body")

  body.className = visible ? "" : "overflow-hidden"
}

const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE": {
      toggleOverflow(state.visible)

      return { ...state, visible: !state.visible }
    }

    case "SHOW_MODAL": {
      toggleOverflow(false)
      return { ...state, visible: true, data: action.payload }
    }

    default:
      return DEFAULT_STATE
  }
}

const ModalContext = createContext()

const ModalProvider = ({ children }) => {
  const [state, dispatcher] = useReducer(reducer, DEFAULT_STATE)

  const toggleModal = () => {
    dispatcher({ type: "TOGGLE" })
  }

  return (
    <ModalContext.Provider value={[state, dispatcher]}>
      {state.visible && state.data ? (
        <Modal data={state.data} onClose={toggleModal} />
      ) : null}
      {children}
    </ModalContext.Provider>
  )
}

const useModal = () => {
  const [state, dispatcher] = useContext(ModalContext)

  const isVisible = state.visible

  const toggleModal = () => {
    dispatcher({ type: "TOGGLE" })
  }

  const showModal = data => {
    dispatcher({ type: "SHOW_MODAL", payload: data })
  }

  return { isVisible, toggleModal, showModal }
}

export { useModal }
export default ModalProvider

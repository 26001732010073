module.exports = {
  homepage: {
    path: "/",
    slugs: {
      pt: "",
      en: "",
    },
  },
  projects: {
    slugs: {
      pt: "projetos",
      en: "projects",
    },
  },
  privacyPolicy: {
    slugs: {
      pt: "politica-de-privacidade",
      en: "privacy-policy",
    },
  },
  cookiesPolicy: {
    slugs: {
      pt: "politica-de-cookies",
      en: "cookies-policy",
    },
  },
}

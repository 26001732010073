import React, { useRef } from "react"

import Section from "@components/Section"
import SectionLine from "@components/SectionLine"
import Amin from "../../assets/amin-logo.jpg"
import Link from "@components/Link"
import { useTranslation } from "react-i18next"
import MultilineText from "@components/MultilineText"

import Lisbon2020 from "../../assets/lisbon2020.svg"
import Portugal2020 from "../../assets/portugal2020.svg"
import EuDevelopmentFund from "../../assets/eu-development-fund.svg"

import { graphql, useStaticQuery } from "gatsby"
import { usePageContext } from "../../providers/pageContext"

const Footer = () => {
  const year = new Date().getFullYear()
  const sectionRef = useRef()
  const { t } = useTranslation()
  const { site } = useStaticQuery(query)
  const pageContext = usePageContext()

  return (
    <Section
      ref={sectionRef}
      id="footer"
      background="negative"
      container
      className="pt-12 pb-16"
    >
      <div className="grid grid-cols-4 md:grid-cols-12 gap-x-8 gap-y-20">
        <div className="col-span-full md:col-span-4">
          <SectionLine width="full" background="white" />
          <h4 className="text-grey mt-12 text-14 uppercase font-base font-bold">
            Network
          </h4>

          <img src={Amin} className="w-1/2 h-auto mt-12 md:mt-20" />

          <h5 className="mt-10 md:mt-12 font-base text-white text-18">
            <MultilineText
              lines={t(`footer.section.amin`, {
                returnObjects: true,
              })}
            />
          </h5>
        </div>
        <div className="col-span-2 md:col-span-4 order-last md:order-none">
          <SectionLine width="full" background="white" />
          <h6 className="text-white mt-10 md:mt-12 text-14 uppercase font-base font-bold">
            Social
          </h6>

          <ul className="mt-14 md:mt-20 font-base font-light text-32 md:text-3xl text-white uppercase underline">
            <li>
              <a
                className="hover:text-blue duration-300 transition-colors"
                href="https://www.facebook.com/comongroup/"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
            </li>
            <li>
              <a
                className="hover:text-blue duration-300 transition-colors"
                href="https://www.linkedin.com/company/comongroup/"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>
            </li>
            <li>
              <a
                className="hover:text-blue duration-300 transition-colors"
                href="https://www.instagram.com/comongroup/"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
            </li>
            <li>
              <a
                className="hover:text-blue duration-300 transition-colors"
                href="https://vimeo.com/comongroup"
                target="_blank"
                rel="noreferrer"
              >
                Vimeo
              </a>
            </li>
            <li>
              <a
                className="hover:text-blue duration-300 transition-colors"
                href="https://medium.com/@comongroup"
                target="_blank"
                rel="noreferrer"
              >
                Medium
              </a>
            </li>
          </ul>
        </div>
        <div className="col-span-2 md:col-span-4">
          <SectionLine width="full" background="white" />
          <h6 className="text-white mt-10 md:mt-12 text-14 uppercase font-base font-bold">
            {t("labels.address")}
          </h6>
          <h4 className="mt-14 md:mt-20 font-base font-light text-32 md:text-3xl text-white uppercase">
            HOOD, <br />
            Rua 3 da Matinha, <br />
            Edifício Altejo 101, <br />
            1950-326 Lisboa
          </h4>
        </div>
      </div>

      <div className="mt-16 md:mt-24 grid grid-cols-4 md:grid-cols-12 gap-x-8 gap-y-4 ">
        <div className="col-span-4">
        <a href={site.siteMetadata.euga[pageContext.lang]} className="inline-flex gap-6" target="_blank" rel="noreferrer">
          <img src={Lisbon2020} alt="Lisboa 2020" className="h-6" />
          <img src={Portugal2020} alt="Portugal 2020" className="h-6" />
          <img
            src={EuDevelopmentFund}
            alt="União Europeia - Fundo Europeu de Desenvolvimento Regional"
            className="h-6"
          />
          </a>
        </div>
        <div className="col-span-4 flex items-center text-white font-base text-14 mt-16 md:mt-0">
          <Link to="privacyPolicy" className="underline uppercase" newTab>
            {t("labels.privacyPolicy")}
          </Link>
          <span className="mx-2">|</span>
          <Link to="cookiesPolicy" className="underline uppercase" newTab>
            {t("labels.cookiesPolicy")}
          </Link>
        </div>
        <div className="col-span-4 md:col-span-4 flex items-center">
          <span className="uppercase text-10 text-white font-base">
            @ {year} COMON{" "}
          </span>
          <div className="w-12 md:w-12 h-px bg-blue mx-2 md:mx-4"></div>
          <span className="uppercase text-10 text-white font-base">
            All Rights Reserved
          </span>
        </div>
      </div>
    </Section>
  )
}

export default Footer

const query = graphql`
  {
    site {
      siteMetadata {
        euga {
          pt
          en
        }
      }
    }
  }
`

import React from "react"
import { useTranslation } from "react-i18next"

import { useModal } from "../providers/modal"

const ContactButton = () => {
  const { showModal } = useModal()
  const [t] = useTranslation()

  const handleContact = () => {
    showModal({
      id: "typeformContact",
      data: {
        theme: {
          background: "#2626FF",
        },
      },
      button: "ghost",
    })
  }

  return (
    <button
      className="leading-snug shadow-pill select-none rounded-full bg-white px-6 py-3 md:p-5 uppercase font-base font-light text-14 transition-all transform hover:bg-blue hover:text-white"
      onClick={handleContact}
    >
      {t("contact.buttons.contactUs")}
    </button>
  )
}

export default ContactButton

import React, { useRef, useEffect, useState } from "react"
import gsap from "gsap"

const MultilineText = ({ lines, animated, animationOptions }) => {
  const refs = useRef([])
  const [played, setPlayed] = useState(false)

  useEffect(() => {
    refs.current = refs.current.slice(0, lines.length)
  }, [lines])

  useEffect(() => {
    if (!animated) return

    const animation = gsap.from(refs.current, {
      scrollTrigger: animationOptions?.trigger
        ? {
            trigger: animationOptions?.trigger,
            start: animationOptions.top || "top center",
          }
        : undefined,
      opacity: 0,
      y: 100,
      stagger: 0.15,
      duration: 0.5,
      ease: "power3.easeOut",
      delay: animationOptions?.delay || 0.1,
    })

    return () => {
      animation.kill()
    }
  }, [animated])

  return (
    <>
      {!lines
        ? null
        : lines.map((line, index) => (
            <span key={index} className="overflow-y-hidden block">
              <span
                className="flex box-border"
                dangerouslySetInnerHTML={{ __html: line }}
                ref={element => {
                  refs.current.push(element)
                }}
              />
            </span>
          ))}
    </>
  )
}

export default MultilineText

import Orb from "./orb"
import ColorPalette from "./colorPalette"

const setupPIXI = async () => {
  const PIXI = await import("pixi.js")
  const { KawaseBlurFilter } = await import("@pixi/filter-kawase-blur")

  return {
    PIXI,
    KawaseBlurFilter,
  }
}

export { setupPIXI, Orb, ColorPalette }

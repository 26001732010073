import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"

//import ContactModalContent from "./ContactModal"
import ProjectModalContent from "./ProjectModal"
import Icon from "../icon"
import { useTranslation } from "react-i18next"
import TypeformContactModalContent from "./TypeformContactModal"

const CloseButton = ({ ghost, onClick }) => {
  const { t } = useTranslation()

  const GhostVariant = ({ onClick }) => (
    <button
      type="button"
      onClick={onClick}
      className="group flex items-center rounded-full p-3 md:px-6 md:py-5 uppercase font-base font-light text-12 leading-none transition-all transform hover:bg-white hover:text-black border-2 border-white border-opacity-12 text-white"
    >
      <Icon
        type="cross"
        size="3"
        color="white"
        className="md:mr-4 md:w-4 md:h-4 group-hover:text-black"
      />
      <span className="hidden md:block">{t("contact.buttons.close")}</span>
    </button>
  )

  const DefaultVariant = ({ onClick }) => (
    <button
      type="button"
      onClick={onClick}
      className="group flex items-center rounded-full p-3 md:px-6 md:py-5 uppercase font-base font-light text-12 leading-none transition-all transform bg-white text-black hover:bg-blue hover:text-white"
    >
      <Icon
        type="cross"
        size="3"
        color="black"
        className="md:mr-4 md:w-4 md:h-4 group-hover:text-white"
      />
      <span className="hidden md:block">{t("contact.buttons.close")}</span>
    </button>
  )

  return ghost ? (
    <GhostVariant onClick={onClick} />
  ) : (
    <DefaultVariant onClick={onClick} />
  )
}

const Modal = ({ data, onClose }) => {
  const [visible, setVisible] = useState(null)
  const modalRef = useRef()

  useEffect(() => {
    if (!data) return

    setVisible(true)
  }, [data])

  const renderContent = () => {
    switch (data.id) {
      //case "contact":
        //return <ContactModalContent data={data}></ContactModalContent>
      case "project":
        return <ProjectModalContent content={data}></ProjectModalContent>
      case "typeformContact":
        return <TypeformContactModalContent />
    }
  }

  const close = () => {
    setVisible(false)
  }

  useEffect(() => {
    if (visible === false) {
      onClose()
    }
  }, [visible])

  return (
    <div
      id="modal"
      ref={modalRef}
      className={`w-screen h-screen fixed left-0 z-50 transition-all ${
        visible ? "opacity-100" : "opacity-0"
      } `}
      style={{
        backgroundColor: data.data.theme.background,
      }}
    >
      <div className="container-fluid flex justify-end mt-7 md:mt-16 absolute left-1/2 transform -translate-x-1/2 z-10">
        <CloseButton onClick={close} ghost={data?.button === "ghost"} />
      </div>
      {data && renderContent()}
    </div>
  )
}

export default Modal

Modal.propTypes = {
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

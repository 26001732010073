import React from "react"

const Circle = ({ size, color, opacity, blur, className }) => {
  const sizes = {
    sm: "circle-sm",
    md: "circle-md",
    lg: "circle-lg",
  }

  const blurs = {
    500: "blur-500",
    300: "blur-300",
    "3xl": "blur-3xl",
    "2xl": "blur-2xl",
  }

  return (
    <div
      className={`rounded-full ${
        sizes[size]
      } bg-${color} bg-opacity-${opacity} filter ${
        blurs[blur] || "blur-3xl"
      } ${className}`}
    />
  )
}

export default Circle

import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"

import gsap from "gsap"

const ProjectModalContent = ({ content }) => {
  const loadingSpinner = useRef(null)
  const { t } = useTranslation()
  const { data, id } = content

  const DEFAULT = {
    main: data.theme.main || "#000000",
    title: data.theme.title || "#000000",
    text: data.theme.text || "#000000",
    details_title: data.theme.details_title || "#000000",
    details_text: data.theme.details_text || "#000000",
  }
  const SECONDARY = {
    title_secondary: data.theme.title_secondary || DEFAULT.title,
    text_secondary: data.theme.text_secondary || DEFAULT.text,
    text_separator: data.theme.text_separator || DEFAULT.main,
  }
  const THEME = Object.assign({}, DEFAULT, SECONDARY)

  const title = useRef(null)
  const description = useRef(null)

  const onLoad = evt => {
    loadingSpinner.current.style.display = "none"

    console.log(data.theme.text_secondary)

    gsap.to(title.current, {
      opacity: 1,
      transform: "translateY(0)",
      delay: 0.2,
      duration: 0.5,
    })
    gsap.to(description.current, {
      opacity: 1,
      transform: "translateY(0)",
      delay: 0.25,
      duration: 0.5,
    })

    gsap.to("#banner", {
      opacity: 1,
      duration: 1.5,
    })
  }

  return (
    <div
      id="scroller"
      className="overflow-y-scroll"
      style={{ maxHeight: "100%" }}
    >
      <div
        ref={loadingSpinner}
        className="absolute flex h-screen w-screen justify-center items-center"
      >
        <svg
          className={`animate-spin -ml-1 mr-3 h-12 w-12 text-white mix-blend-exclusion`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
      <div
        id="banner"
        className="rounded-lg px-2 py-2 mx-auto hidden md:block opacity-0"
        style={{
          maxWidth: "1920px",
          width: "100%",
          height: "auto",
        }}
      >
        <img
          className="rounded-lg"
          src={data.image.desktop}
          style={{
            width: "100%",
          }}
          onLoad={onLoad}
        />
      </div>

      <div
        id="banner"
        className="rounded-lg px-2 py-2 mx-auto md:hidden opacity-0"
        style={{
          maxWidth: "1920px",
          width: "100%",
          height: "auto",
        }}
      >
        <img
          className="rounded-lg"
          src={data.image.mobile}
          style={{
            width: "100%",
          }}
        />
      </div>

      <div className="container-fluid flex flex-wrap md:flex-nowrap">
        <div
          ref={title}
          style={{ opacity: "0", transform: "translateY(100px)" }}
          className="w-full md:w-5/12 mr-8 pointer-events-none"
        >
          <ProjectItemTitle
            client={t([data.i18n, "projects", data.id, "client"].join("."))}
            project={t([data.i18n, "projects", data.id, "project"].join("."))
              .split("<br>")
              .join(" ")}
            color_text={THEME.title_secondary}
            color_separator={THEME.text_separator}
          />
          <div
            className="w-full h-auto font-base uppercase mt-8 text-40 pointer-events-none"
            style={{
              color: THEME.text_secondary,
            }}
            dangerouslySetInnerHTML={{
              __html: t(
                [data.i18n, "projects", data.id, "description"].join(".")
              )
                .split("<br>")
                .join(" "),
            }}
          ></div>
        </div>
        <div
          ref={description}
          style={{ opacity: "0", transform: "translateY(100px)" }}
          className="w-full md:w-7/12 flex flex-wrap lg:flex-no-wrap mt-16 pointer-events-none"
        >
          <div className="w-full mb-8 flex flex-col pointer-events-none">
            <ProjectItemDescription
              title={t("labels.modals.projects.challenge")}
              description={t(
                [data.i18n, "projects", data.id, "challenge"].join(".")
              )}
              color_title={THEME.details_title}
              color_description={THEME.details_text}
            />
          </div>
          <div className="w-full mb-8 flex flex-col pointer-events-none">
            <ProjectItemDescription
              title={t("labels.modals.projects.solution")}
              description={t(
                [data.i18n, "projects", data.id, "solution"].join(".")
              )}
              color_title={THEME.details_title}
              color_description={THEME.details_text}
            />
          </div>
          <div
            className={`pointer-events-none w-full flex flex-col ${
              t([data.i18n, "projects", data.id, "results"].join(".")).length ==
              0
                ? "invisible"
                : ""
            }`}
          >
            <ProjectItemDescription
              title={t("labels.modals.projects.results")}
              description={t(
                [data.i18n, "projects", data.id, "results"].join(".")
              )}
              color_title={THEME.details_title}
              color_description={THEME.details_text}
            />
          </div>
        </div>
      </div>
      <div className="h-12"></div>
    </div>
  )
}

export default ProjectModalContent

ProjectModalContent.propTypes = {
  content: PropTypes.object.isRequired,
}

const ProjectItemTitle = ({ client, project, color_text, color_separator }) => {
  return (
    <div className="pt-16">
      <h6 className="mr-1 font-base uppercase " style={{ color: color_text }}>
        {client}
        <span className="mx-1" style={{ color: color_separator }}>
          -
        </span>
        {project}
      </h6>
    </div>
  )
}
ProjectItemTitle.propTypes = {
  client: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
  color_text: PropTypes.string.isRequired,
  color_separator: PropTypes.string.isRequired,
}

const ProjectItemDescription = ({
  title,
  description,
  color_title,
  color_description,
}) => {
  return (
    <>
      <div
        className="font-space-grotesk mb-8 uppercase"
        style={{ color: color_title }}
      >
        {title}
      </div>
      <div
        className="font-space-grotesk"
        dangerouslySetInnerHTML={{
          __html: description,
        }}
        style={{ color: color_description }}
      ></div>
    </>
  )
}

ProjectItemDescription.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  color_title: PropTypes.string.isRequired,
  color_description: PropTypes.string.isRequired,
}

import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Widget } from "@typeform/embed-react"
import { usePageContext } from "../../providers/pageContext"

const TypeformContactModal = () => {
  const pageContext = usePageContext()
  const { site } = useStaticQuery(query)

  return (
    <div className="w-full h-full bg-blue overflow-auto pb-24">
      <div className={`container-fluid pt-32 md:pt-40 h-full`}>
        <Widget
          id={site.siteMetadata.forms[pageContext.lang]}
          inlineOnMobile
          className="h-full"
        />
      </div>
    </div>
  )
}

export default TypeformContactModal

const query = graphql`
  {
    site {
      siteMetadata {
        forms {
          pt
          en
        }
      }
    }
  }
`
